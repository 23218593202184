// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-hcp-404-js": () => import("./../../../src/pages/hcp/404.js" /* webpackChunkName: "component---src-pages-hcp-404-js" */),
  "component---src-pages-hcp-clinical-data-js": () => import("./../../../src/pages/hcp/clinical-data.js" /* webpackChunkName: "component---src-pages-hcp-clinical-data-js" */),
  "component---src-pages-hcp-clinical-safety-profile-js": () => import("./../../../src/pages/hcp/clinical-safety-profile.js" /* webpackChunkName: "component---src-pages-hcp-clinical-safety-profile-js" */),
  "component---src-pages-hcp-dosing-and-administration-js": () => import("./../../../src/pages/hcp/dosing-and-administration.js" /* webpackChunkName: "component---src-pages-hcp-dosing-and-administration-js" */),
  "component---src-pages-hcp-index-js": () => import("./../../../src/pages/hcp/index.js" /* webpackChunkName: "component---src-pages-hcp-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-why-js": () => import("./../../../src/pages/why.js" /* webpackChunkName: "component---src-pages-why-js" */),
  "component---src-pages-workflow-scenarios-js": () => import("./../../../src/pages/workflow-scenarios.js" /* webpackChunkName: "component---src-pages-workflow-scenarios-js" */)
}

