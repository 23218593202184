import React, { useState, useEffect } from 'react';
import appConfigs from '../../../app.settings';
import { CookiesProvider } from 'react-cookie';

export const appContext = React.createContext();

const AppProvider = props => {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [currentIndication, setCurrentIndication] = useState('');
  const [magicshot, setMagicshot] = useState(false);
  const [topOffset, setTopOffset] = useState(0);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const closeMobileNav = () => {
    setMobileNavOpen(true);
    setTimeout(() => {
      setMobileNavOpen(false);
    }, 20);
  };

  const updateHeaderHeight = _height => {
    setHeaderHeight(_height);
  };

  const updateCurrentIndication = _indication => {
    setCurrentIndication(_indication);
  };

  const updateMagicshot = _flag => {
    setMagicshot(_flag);
  };

  useEffect(() => {
    appConfigs.siteOptions.HEADER_TYPE === 'fixed'
      ? setTopOffset(headerHeight)
      : setTopOffset(0);
  }, [headerHeight]);
  return (
    <appContext.Provider
      value={{
        appConfigs,
        currentIndication,
        headerHeight,
        magicshot,
        topOffset,
        mobileNavOpen,
        updateHeaderHeight,
        updateCurrentIndication,
        updateMagicshot,
        closeMobileNav,
      }}
    >
      {props.children}
    </appContext.Provider>
  );
};

export default ({ element }) => (
  <AppProvider>
    <CookiesProvider>{element}</CookiesProvider>
  </AppProvider>
);
