const metaData = {
  SITE_NAME: `Ephedrine Sulfate Injection`,
  SITE_DEFAULT_DESCRIPTION: `Test product`,
  SITE_DEFAULT_KEYWORDS: ['brandx', 'brand', 'dfa', 'deerfield'],
  PATIENT_JOB_CODE: `EIS-EH-05000`,
  HCP_JOB_CODE: `XX-XXXX/March 2020`,
  CURRENT_YEAR: new Date().getFullYear(),
};

/**
 * HEADER_TYPE : fixed or regular
 * ISI_OPTIONS["TYPE"] : sticky or regular
 */
const ISI_HEIGHT = 180;
const siteOptions = {
  INDICATIONS: ['hcp', 'patient'],
  HEADER_TYPE: 'fixed',
  STICKY_HEIGHT: ISI_HEIGHT,
  ISI_OPTIONS: {
    TYPE: 'sticky',
    STICKY_STYLES: {
      backgroundColor: '#F6F6F6',
      height: ISI_HEIGHT,
      width: '100%',
      boxShadow: '-1px -5px 15px 0px rgba(0,0,0,0.10)',
      paddingTop: 5,
    },
  },
};
const hcpModalInfo = {
  TITLE: `<h2>Intended for U.S. Residents Only<h2>`,
  BODY: `<h3>Welcome to BRANDX.com</h3><p>Are you a patient or a healthcare professional?</p>`,
  BUTTON_ONE_CAPTION: `I am a patient`,
  BUTTON_TWO_CAPTION: `I am a healthcare professional`,
  CLASS_NAME: `brandx-modal brandx-hcp-modal`,
};
const externalLinkInfo = {
  TITLE: `BRANDX Website Message`,
  BODY: `You are going away from BRANDX website. Do you want to continue?`,
  LINK_CLASS_NAME: `brandx-external-link`,
  OVERLAY_CLASS_NAME: `brandx-modal brandx-external-modal`,
};

const appConfigs = {
  metaData: metaData,
  siteOptions: siteOptions,
  hcpModalInfo: hcpModalInfo,
  externalLinkInfo: externalLinkInfo,
};

export default appConfigs;
